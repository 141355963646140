import React, { useEffect, useState } from "react";
import { getHourAndMinuteUTC, timeFormatter } from "../../utils/dateFormatter";
import {
  PaperAirplaneIcon,
  BoltIcon,
  ClockIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import Pill from "../UI/Pill";
import WhatsAppModal from "../UI/WhatsAppModal";
import { useSelector } from "react-redux";
import { updateWASent } from "../../firebase/firebase.praxis";
import useWhatsApp from "../../hooks/whatsApp/useWhatsApp";

const ReminderList = ({
  today,
  tomorrow,
  praxisId,
  setToday,
  setTomorrow,
  setForceUpdate,
}) => {
  const [isToday, setIsToday] = useState(true);
  const [todays, setTodays] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);
  // const { sendWhatsAppMessage } = useWhatsApp();

  const sendReminder = (e, day) => {
    let praxisNameStr = praxis.praxisName.replaceAll(" ", "%20") + "%20";
    let praxisAddressStr = praxis.address.replaceAll(" ", "%20") + "%20";
    let newAppointment = getHourAndMinuteUTC(new Date(e.start));
    let newAppointmentEnd = getHourAndMinuteUTC(new Date(e.end));
    let apointmentTime = newAppointment; //newAppointment.toLocaleTimeString()?.slice(0, 5);
    let apointmentEndTime = newAppointmentEnd; //newAppointmentEnd.toLocaleTimeString()?.slice(0, 5);
    // let url = `https://wa.me/${e.patientTel}?text=${praxis?.WAMessage?.message}`;
    let url = `https://api.whatsapp.com/send?phone=${e.patientTel}&text=${praxis?.WAMessage?.message}`;
    url = url.replaceAll("{nombre}", e.patientName);
    url = url.replaceAll("{consultorio}", praxis.praxisName);
    url = url.replaceAll("{comienza}", apointmentTime);
    url = url.replaceAll("{termina}", apointmentEndTime);
    url = url.replaceAll("{direccion}", praxisAddressStr);
    console.log(e);
    updateWASent(e.event_id);
    if (day == "today") {
      let todayApts = [...today];
      console.log(todayApts);

      todayApts.find((t) => t.event_id == e.event_id).contacted = true;
      setToday(todayApts);
    } else if (day == "tomorrow") {
      let tomorrowsApt = JSON.parse(JSON.stringify(tomorrow));
      tomorrowsApt.find((t) => t.event_id == e.event_id).contacted = true;
      setTomorrow(tomorrowsApt);
    }
    window.open(url, "_blank");
  };

  useEffect(() => {
    let sortByDate = today.sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });
    setTodays(sortByDate);
  }, [today]);

  useEffect(() => {
    // // console.log(praxis?.WAMessage?.message);
  }, [praxis?.WAMessage?.message]);

  const sendMessage = async () => {
    try {
      // // console.log(praxis);
      debugger;
      const response = await fetch(
        "http://127.0.0.1:5001/odontostarkdev/us-central1/testDailyReminderCheck",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   to: patientTel,
          //   message: message,
          //   patientName: patientName,
          //   praxisName: praxisName,
          //   start: start,
          //   address: praxis?.address,
          //   praxisPhone: praxis?.praxisPhone,
          // }),
        }
      );
      debugger;
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      debugger;
      const data = await response.json();
      // console.log(data);
      // setResponse(data);
    } catch (error) {
      debugger;
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  };
  return (
    <>
      <div className="border-0 rounded-lg shadow-lg outline-none focus:outline-none px-2 mt-2 bg-slate-700">
        {
          <>
            <div className="flex flex-col w-full justify-center p-2 m-2">
              <div className="flex justify-between items-center mx-1 mb-2">
                <div className="font-bold text-white text-xl">
                  Tu dia de hoy
                </div>
                <BoltIcon
                  className="h-5 w-5 text-white cursor-pointer"
                  onClick={() => setOpenModal(!openModal)}
                />
              </div>

              {/* Date Switcher */}
              <div className="flex mb-2">
                <div
                  key="today"
                  className={`m-1 cursor-pointer ${
                    isToday ? "text-yellow-300" : "text-white"
                  }`}
                  onClick={() => setIsToday(true)}
                >
                  Hoy
                </div>
                <div
                  key="tomorrow"
                  className={`m-1 cursor-pointer ${
                    !isToday ? "text-yellow-300" : "text-white"
                  }`}
                  onClick={() => setIsToday(false)}
                >
                  Mañana
                </div>
              </div>

              {/* {!isToday && (
                <div onClick={sendMessage} key="recordatorio">
                  Enviar Recordatorio a todos
                </div>
              )} */}

              {/* Appointment List */}
              <div className="overflow-auto h-[50vh]">
                {isToday
                  ? todays.map((e, i) => (
                      <div
                        key={`${e?.id}-${i}`}
                        className="p-2 mb-2 border border-gray-200 rounded-lg shadow-md bg-white"
                      >
                        <div className="flex justify-between items-center mb-1">
                          {/* Date */}
                          <div className="text-base font-semibold text-gray-800">
                            {e?.start.seconds === undefined
                              ? timeFormatter(e?.start)
                              : timeFormatter(new Date(e?.start.toDate()))}
                          </div>

                          {/* Patient Name */}
                          <div className="text-base font-semibold text-gray-800 truncate w-1/2">
                            {e?.patientName}
                          </div>
                        </div>
                        {/* Confirmation Status */}
                        <div className="text-xs font-medium text-gray-600 mt-2">
                          {e?.confirmed !== null ? (
                            e?.confirmed ? (
                              <Pill text="Confirmado" color="green"></Pill>
                            ) : (
                              <Pill text="Cancelado" color="red"></Pill>
                            )
                          ) : (
                            <Pill text="Pendiente" color="yellow"></Pill>
                          )}
                        </div>

                        <div className="flex items-center justify-between p-1">
                          {/* Contact Status */}
                          <div
                            className={`flex items-center text-sm font-medium ${
                              e?.contacted ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {e?.contacted ? (
                              <CheckCircleIcon className="h-3 w-3 mr-1" />
                            ) : (
                              <ExclamationCircleIcon className="h-3 w-3 mr-1" />
                            )}
                            {e?.contacted ? (
                              <div className="text-xs">Contactado</div>
                            ) : (
                              <div className="text-xs">Sin Contactar</div>
                            )}
                          </div>

                          {/* Compact Icon-Only Reminder Button */}
                          <button
                            className="p-1 bg-gray-200 text-gray-600 rounded-md hover:bg-blue-500 hover:text-white transition-all ml-2"
                            onClick={() => sendReminder(e, "today")}
                          >
                            <PaperAirplaneIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    ))
                  : tomorrow.map((e, i) => (
                      <div
                        key={`${e?.id}-${i}`}
                        className="p-2 mb-2 border border-gray-200 rounded-lg shadow-md bg-white"
                      >
                        <div className="flex justify-between items-center mb-1">
                          {/* Date */}
                          <div className="text-base font-semibold text-gray-800">
                            {e?.start.seconds === undefined
                              ? timeFormatter(e?.start)
                              : timeFormatter(new Date(e?.start.toDate()))}
                          </div>

                          {/* Patient Name */}
                          <div className="text-base font-semibold text-gray-800 truncate w-1/2">
                            {e?.patientName}
                          </div>
                        </div>
                        {/* Confirmation Status */}
                        <div className="text-xs font-medium text-gray-600 mt-2">
                          {e?.confirmed !== null ? (
                            e?.confirmed ? (
                              <Pill text="Confirmado" color="green"></Pill>
                            ) : (
                              <Pill text="Cancelado" color="red"></Pill>
                            )
                          ) : (
                            <Pill text="Pendiente" color="yellow"></Pill>
                          )}
                        </div>

                        <div className="flex items-center justify-between p-1">
                          {/* Contact Status */}
                          <div
                            className={`flex items-center text-sm font-medium ${
                              e?.contacted ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {e?.contacted ? (
                              <CheckCircleIcon className="h-3 w-3 mr-1" />
                            ) : (
                              <ExclamationCircleIcon className="h-3 w-3 mr-1" />
                            )}
                            {e?.contacted ? (
                              <div className="text-xs">Contactado</div>
                            ) : (
                              <div className="text-xs">Sin Contactar</div>
                            )}
                          </div>

                          {/* Compact Icon-Only Reminder Button */}
                          <button
                            className="p-1 bg-gray-200 text-gray-600 rounded-md hover:bg-blue-500 hover:text-white transition-all ml-2"
                            onClick={() => sendReminder(e, "tomorrow")}
                          >
                            <PaperAirplaneIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </>
        }
      </div>
      {openModal && (
        <WhatsAppModal
          praxisId={praxisId}
          defaultMessage={praxis?.WAMessage?.message}
          modalToggler={setOpenModal}
        />
      )}
    </>
  );
};

export default ReminderList;
